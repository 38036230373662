body { background-color: var(--clr-1459); }
body { color: var(--clr-1460); }
[data-pad="0"] { margin-top: 20px; }

[data-pad="1"] { margin-top: 5px; }

h1 {
color: var(--clr-1460);
font-family: 'Open Sans';
font-weight: 700;
font-style: normal;
line-height: 1.1;
font-size: 25px;

@media #{$medium-up} {
font-size: 40px;

}
}
h2 {
color: var(--clr-1460);
font-family: 'Open Sans';
font-weight: 700;
font-style: normal;
line-height: 1.5;
font-size: 22px;

@media #{$medium-up} {
font-size: 35px;

}
}
h3 {
color: var(--clr-1461);
font-family: 'Open Sans';
font-weight: 700;
font-style: normal;
line-height: 1.5;
font-size: 20px;

@media #{$medium-up} {
font-size: 30px;

}
}
h4 {
color: var(--clr-1460);
font-family: 'Open Sans';
font-weight: 700;
font-style: normal;
line-height: 1.5;
font-size: 18px;

@media #{$medium-up} {
font-size: 25px;

}
}
h5 {
color: var(--clr-1460);
font-family: 'Open Sans';
font-weight: 700;
font-style: normal;
line-height: 1.5;
font-size: 17px;

@media #{$medium-up} {
font-size: 20px;

}
}
h6 {
color: var(--clr-1460);
font-family: 'Open Sans';
font-weight: 700;
font-style: normal;
line-height: 1.5;
font-size: 16px;

@media #{$medium-up} {
font-size: 18px;

}
}
.button {
font-family: 'Open Sans';
font-weight: 700;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
.me-Quote .quote-body, .me-HtmlText blockquote {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
.me-Quote .quote-author {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
ol,ul {
color: var(--clr-1460);
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
ol,ul { margin-bottom: 1em; list-style-position: outside; }
ol { margin-left: 1.25em; list-style-type: decimal; }
ul { margin-left: 1.25em; list-style-type: disc; }
ul, ol { & & { margin-bottom: 0 } }
cite {
color: 14;
font-family: 'Open Sans';
font-weight: 300;
font-style: normal;
line-height: 1.5;
font-size: 16px;

@media #{$medium-up} {
font-size: 18px;

}
@media #{$large-up} {
font-size: 20px;

}
}
summary {
font-family: 'Open Sans';
font-weight: 700;
font-style: normal;
line-height: 1.5;
font-size: 18px;

@media #{$medium-up} {
font-size: 25px;

}
}
nav.mainmenu > .menu-item > a {
font-family: 'Open Sans';
font-weight: 600;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.mainmenu > .menu-item > div > a {
font-family: 'Open Sans';
font-weight: 600;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.secondarymenu > .menu-item > a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.me-RespMenu.responsive-menu a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 20px;

}
body {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
p { padding-bottom: 1em; }
a {color: var(--clr-1461);}
/* Primary:2 */
.MES2 {
background-color: var(--clr-1461);
color: #ffffff;
 }
/* Primary:3 */
.MES3 {
background-color: var(--clr-1461);
color: #ffffff;
 }
.MES3 {
background-color: var(--clr-1461);
color: #ffffff;
h1.MEC3, h2.MEC3, h3.MEC3, h4.MEC3, h5.MEC3, h6.MEC3 { color: #ffffff;
 }
 }
a.MEC3 { color: var(--clr-1459);
 }
.MEC3 li {color: var(--clr-1459-flat);}
cite.MEC3{
color: #ffffff;
}
/* Secondary:4 */
.MES4 {
background-color: var(--clr-1460);
color: #ffffff;
 }
/* Secondary:5 */
.MES5 {
background-color: var(--clr-1460);
color: #ffffff;
font-size: 14px;
@media #{$medium-up} {
font-size: 16px;
};
 }
.MES5 {
background-color: var(--clr-1460);
color: #ffffff;
font-size: 14px;
@media #{$medium-up} {
font-size: 16px;
};
h1.MEC5, h2.MEC5, h3.MEC5, h4.MEC5, h5.MEC5, h6.MEC5 { color: #ffffff;
 }
 }
a.MEC5 { color: #ffffff;
&:hover { color: var(--clr-1459);}
 }
.MEC5 li {color: var(--clr-1459-flat);}
cite.MEC5{
color: #ffffff;
font-size: 14px;
@media #{$medium-up} {
font-size: 16px;
};
}
/* Header:6 */
.MES6 {
background-color: var(--clr-1462);
color: #000000;
 }
.MES6 {
background-color: var(--clr-1462);
color: #000000;
h1.MEC6, h2.MEC6, h3.MEC6, h4.MEC6, h5.MEC6, h6.MEC6 { color: #000000;
 }
 }
a.MEC6 { color: #000000;
 }
cite.MEC6{
color: #000000;
}
/* Main Menu:7 */
nav.me-Menu.MES7 {
& .menu-item.MEC7, & .menu-item.MEC7 > div.MEC7{ & > a.MEC7{color: #000000;
text-transform: uppercase;
}
 &:hover > a.MEC7{color: var(--clr-1461);
}
 }
&.horizontal > .menu-item.MEC7 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC7 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC7 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC7 .sub-menu { border:1px;
border-color: 22;
border-style: solid;
 }
& > .menu-item > a{padding: 0 20px;}

& .sub-menu .menu-item a{padding: 10px 0;}



&.horizontal .menu-item.MEC7:hover {& > .pointer-wrap > .pointer{border-bottom: 10px solid #ffffff;}}
&.vertical .menu-item.MEC7:hover {& > .pointer-wrap > .pointer{border-right: 10px solid #ffffff;
}}

.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {
.menu-item.MEC7{background-color: #ffffff; &:hover {background-color: 2;}
}
& .menu-item.MEC7, & .menu-item.MEC7 > div.MEC7{ & > a.MEC7{color: #000000;
}
 &:hover > a.MEC7{color: 20;
}
 }

}
}
 }
/* Home Slider:8 */
.MES8 {
& .slider-arrow {color: var(--clr-1459);
font-size: 80px;
@media #{$medium-up} {
font-size: 80px;
};
@media #{$large-up} {
font-size: 80px;
};
}& .slider-arrow:hover{color: var(--clr-1459);
} }
/* Parallax 400px:9 */
.MES9 {
background-color: #000000;
& > .underlay, & > .inner-overlay { opacity: 0.4 }
& {overflow: hidden; }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://westerncaravans.com.au/img/1433/14');
background-position: center center;
background-repeat: no-repeat;
top: -5%;
height: 150%;
background-size: cover;
min-height: 0.1px;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: var(--clr-1459);
min-height:400px;
@media #{$medium-up} {
min-height: 400px;};
@media #{$large-up} {
min-height: 400px;};
padding: 15px;

@media #{$medium-up} {
padding: 50px 15px;

}
@media #{$large-up} {
padding: 100px 15px;

}
 }
.MES9 {
background-color: #000000;
& > .underlay, & > .inner-overlay { opacity: 0.4 }
& {overflow: hidden; }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://westerncaravans.com.au/img/1433/14');
background-position: center center;
background-repeat: no-repeat;
top: -5%;
height: 150%;
background-size: cover;
min-height: 0.1px;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: var(--clr-1459);
min-height:400px;
@media #{$medium-up} {
min-height: 400px;};
@media #{$large-up} {
min-height: 400px;};
padding: 15px;

@media #{$medium-up} {
padding: 50px 15px;

}
@media #{$large-up} {
padding: 100px 15px;

}
h1.MEC9, h2.MEC9, h3.MEC9, h4.MEC9, h5.MEC9, h6.MEC9 { color: var(--clr-1461);
 }
 }
cite.MEC9{
color: var(--clr-1459);
}
/* parallax no height:11 */
.MES11 {
background-color: #000000;
& > .underlay, & > .inner-overlay { opacity: 0.4 }
& {overflow: hidden; }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://westerncaravans.com.au/img/1433/14');
background-position: center center;
background-repeat: no-repeat;
top: -5%;
height: 170%;
background-size: cover;
min-height: 0.1px;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: var(--clr-1459);
 }
.MES11 {
background-color: #000000;
& > .underlay, & > .inner-overlay { opacity: 0.4 }
& {overflow: hidden; }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://westerncaravans.com.au/img/1433/14');
background-position: center center;
background-repeat: no-repeat;
top: -5%;
height: 170%;
background-size: cover;
min-height: 0.1px;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: var(--clr-1459);
h1.MEC11, h2.MEC11, h3.MEC11, h4.MEC11, h5.MEC11, h6.MEC11 { color: var(--clr-1459);
 }
 }
cite.MEC11{
color: var(--clr-1459);
}
/* Light:12 */
.MES12 {
background-color: #ffffff;
color: var(--clr-1463);
 }
/* Light:13 */
.MES13 {
background-color: #ffffff;
color: var(--clr-1463);
 }
.MES13 {
background-color: #ffffff;
color: var(--clr-1463);
h1.MEC13, h2.MEC13, h3.MEC13, h4.MEC13, h5.MEC13, h6.MEC13 { color: var(--clr-1461);
 }
 }
cite.MEC13{
color: var(--clr-1463);
}
/* Banner:14 */
.MES14 {
background-color: var(--clr-1464);
color: var(--clr-1459);
 }
.MES14 {
background-color: var(--clr-1464);
color: var(--clr-1459);
h1.MEC14, h2.MEC14, h3.MEC14, h4.MEC14, h5.MEC14, h6.MEC14 { color: var(--clr-1459);
 }
 }
a.MEC14 { color: var(--clr-1459);
 }
cite.MEC14{
color: var(--clr-1459);
}
/* Heading:15 */
.MES15 {
color: var(--clr-1460);
font-size: 12.6px;
@media #{$medium-up} {
font-size: 14.4px;
};
 }
.MES15 {
color: var(--clr-1460);
font-size: 12.6px;
@media #{$medium-up} {
font-size: 14.4px;
};
 }
cite.MEC15{
color: var(--clr-1460);
font-size: 12.6px;
@media #{$medium-up} {
font-size: 14.4px;
};
}
/* Heading Separator:16 */
.MES16 {
& > hr {border-width: 3px 0 0 0;}
& > hr {border-color: var(--clr-1461) transparent transparent transparent;}
& > hr {border-top-style: solid;}
 }
/* Hollow:17 */
.MES17 {
color: #ffffff;
&:hover { color: var(--clr-1459);}
border-radius: 3px;
padding: 10px 50px;

border-width: 1px;
border-style: solid;
border-color: #ffffff;
&:hover { border-color: var(--clr-1459); }
 }
/* Heading Separator Inverse:18 */
.MES18 {
& > hr {border-width: 3px 0 0 0;}
& > hr {border-color: #ffffff transparent transparent transparent;}
& > hr {border-top-style: solid;}
 }
/* Content:19 */
.MES19 {
background-color: #ffffff;
color: var(--clr-1463);
 }
.MES19 {
background-color: #ffffff;
color: var(--clr-1463);
h1.MEC19, h2.MEC19, h3.MEC19, h4.MEC19, h5.MEC19, h6.MEC19 { color: var(--clr-1460);
 }
 }
cite.MEC19{
color: var(--clr-1463);
}
/* Banner:20 */
.MES20 {
background-color: #000000;
& > .underlay, & > .inner-overlay { opacity: 0.69 }
& {overflow: hidden; }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://westerncaravans.com.au/img/1433/14');
background-position: center center;
background-repeat: no-repeat;
top: -5%;
height: 150%;
background-size: cover;
min-height: 0.1px;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: var(--clr-1459);
padding: 147px 15px 30px 15px;

@media #{$large-up} {
padding: 229px 15px 100px 15px;

}
 }
.MES20 {
background-color: #000000;
& > .underlay, & > .inner-overlay { opacity: 0.69 }
& {overflow: hidden; }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://westerncaravans.com.au/img/1433/14');
background-position: center center;
background-repeat: no-repeat;
top: -5%;
height: 150%;
background-size: cover;
min-height: 0.1px;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: var(--clr-1459);
padding: 147px 15px 30px 15px;

@media #{$large-up} {
padding: 229px 15px 100px 15px;

}
h1.MEC20, h2.MEC20, h3.MEC20, h4.MEC20, h5.MEC20, h6.MEC20 { color: var(--clr-1459);
 }
 }
cite.MEC20{
color: var(--clr-1459);
}
/* Statement panel:21 */
.MES21 {
background-color: var(--clr-1460);
color: #ffffff;
font-size: 14px;
@media #{$medium-up} {
font-size: 17.6px;
};
@media #{$large-up} {
font-size: 19.2px;
};
padding: 20px;

 }
.MES21 {
background-color: var(--clr-1460);
color: #ffffff;
font-size: 14px;
@media #{$medium-up} {
font-size: 17.6px;
};
@media #{$large-up} {
font-size: 19.2px;
};
padding: 20px;

h1.MEC21, h2.MEC21, h3.MEC21, h4.MEC21, h5.MEC21, h6.MEC21 { color: #ffffff;
 }
 }
a.MEC21 { color: #ffffff;
&:hover { color: var(--clr-1459);}
 }
.MEC21 li {color: var(--clr-1459-flat);}
cite.MEC21{
color: #ffffff;
font-size: 14px;
@media #{$medium-up} {
font-size: 17.6px;
};
@media #{$large-up} {
font-size: 19.2px;
};
}
/* home panel:22 */
.MES22 {
background-color: #ffffff;
& > .overlay, & > .panel-link { 
  &:hover, &.hover {background-color: var(--clr-1465);}}
& > .overlay {
position: absolute;
top:0;
bottom:0;
left: 0;
right: 0;
z-index: 1;
}
 }
.MES22 {
background-color: #ffffff;
& > .overlay, & > .panel-link { 
  &:hover, &.hover {background-color: var(--clr-1465);}}
& > .overlay {
position: absolute;
top:0;
bottom:0;
left: 0;
right: 0;
z-index: 1;
}
 }
/* Footer banner:23 */
.MES23 {
color: #ffffff;
font-size: 11.2px;
@media #{$medium-up} {
font-size: 12.8px;
};
 }
.MES23 {
color: #ffffff;
font-size: 11.2px;
@media #{$medium-up} {
font-size: 12.8px;
};
 }
a.MEC23 { color: #ffffff;
&:hover { color: #ffffff;}
 }
cite.MEC23{
color: #ffffff;
font-size: 11.2px;
@media #{$medium-up} {
font-size: 12.8px;
};
}
/* copyright:24 */
.MES24 {
font-size: 11.2px;
@media #{$medium-up} {
font-size: 12.8px;
};
 }
.MES24 {
font-size: 11.2px;
@media #{$medium-up} {
font-size: 12.8px;
};
h1.MEC24 { font-size: 20px; }
h2.MEC24 { font-size: 17.6px; }
h3.MEC24 { font-size: 16px; }
h4.MEC24 { font-size: 14.4px; }
h5.MEC24 { font-size: 13.6px; }
h6.MEC24 { font-size: 12.8px; }
 }
cite.MEC24{
font-size: 11.2px;
@media #{$medium-up} {
font-size: 12.8px;
};
}
